import { ElementRole } from '../../constants/elements';
import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      $w(`#${ElementRole.RibbonText}`).text = $widget.props.text;
    },
    exports: {
      getTextElement: () => {
        return $w(`#${ElementRole.RibbonText}`);
      },
    },
  };
});
